import React, { useRef, useImperativeHandle, forwardRef } from 'react';
import { ExamSpaceUploadResource } from '../../helpers/requests';
import { toast } from "react-toastify";

function FileUploadComponent(props, ref) {
	const fileInputRef = useRef(null);
	var current_domain = window.location.origin;
	var upload_file_error_text = `<p>You can have only 1 document at a time in free mode.</p> <a href="${current_domain}/pushmode"><p style="color: #f18f01;">Go push mode to go unlimited</p></a>`;
	var delete_file_error_text = `<p>You will not be able to upload any other document as you reach your limit of 3 for the free mode.</p> <a href="${current_domain}/pushmode"><p style="color: #f18f01;">Go push mode to go unlimited</p></a>`;
	
	useImperativeHandle(ref, function() {
		return {
			triggerFileInput: function() {
				fileInputRef.current.click();
			}
		};
	});
	
	
	async function handleFileUpload(e) {
		try {
			const userUploadedDoc = e.target.files[0];
			if (userUploadedDoc && userUploadedDoc.type === "application/pdf") {
				if(Math.round(userUploadedDoc.size / (1000 * 1000)) < 40){
					toast.success("File uploading...", {
						position: toast.POSITION.TOP_CENTER,
						autoClose: false,
					});
					const response = await ExamSpaceUploadResource(userUploadedDoc, props.examspaceId);
					if (response.status === true) {
						toast.dismiss();
						if(response.message.toLowerCase() === "file upload success"){
							toast.success("File uploaded successfully", {
								position: toast.POSITION.TOP_CENTER,
							});
						} else {
							toast.error(response.message, {
								position: toast.POSITION.TOP_CENTER,
							});
						}
						props.onUploadSuccess(response);
					} else if (response.status === false) {
						toast.dismiss();
						if(response.message === "In free plan you can't upload more than 1 file at a time"){
							toast.error(
								<div dangerouslySetInnerHTML={{ __html: upload_file_error_text }} />,
								{
									position: toast.POSITION.TOP_CENTER,
								}
							);
						} else if(response.message === "In free plan you can't upload more than 3 files"){
							toast.error(
								<div dangerouslySetInnerHTML={{ __html: delete_file_error_text }} />,
								{
									position: toast.POSITION.TOP_CENTER,
								}
							);
						} else {
							toast.warning(response.message, {
								position: toast.POSITION.TOP_CENTER,
							}); 					
						}
					}				
				} else {
					toast.error("Oops, your file is too large. Please upload max 40MB files", {
						position: toast.POSITION.TOP_CENTER,
					});				
				}
			} else {
				toast.error("Only .pdf format are supported at the moment", {
					position: toast.POSITION.TOP_CENTER,
				});      
			}
		} catch (error) {
			console.error(error)
		} finally {
			fileInputRef.current.value = "";
		}    
		
	}
		
	return (
		<input type="file" ref={fileInputRef} hidden accept=".pdf" onChange={(e)=>{handleFileUpload(e)}}/>
	);
}
		
export default forwardRef(FileUploadComponent);